import type { DeviceSizable } from ".";

const isResiable = <T>(value: any): value is DeviceSizable<T> => {
  return value?.mobile || value?.desktop;
};

export const useResizable = <T>(value: DeviceSizable<T> | T): T | undefined => {
  if (!isResiable(value)) {
    return value;
  }

  const mobile = useMobile();

  if (mobile.value) {
    return value.mobile;
  }

  return value.desktop;
};
